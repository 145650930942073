<template>
	<div v-if="!hideLoginForm" id="login" class="input-heading" :class="playerState.loggedIn ? 'loggedin' : ''">
		<div class="login-status" v-if="playerState.loggedIn && !isMobile">
			<span class="display-name">{{ playerState.displayName }}</span>
			<button class="btn logout-button" type="button" :title="languageStrings.logout" @click="showLogoutDialog()">
				{{ languageStrings.logout }}
			</button>
			<span v-if="playerState.permissionsSuspended" class="warn">{{ languageErrorStrings.suspended }}</span>
		</div>
		<div v-if="serverBusy" id="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<form class="input-section encore-background" v-if="!playerState.loggedIn">
			<img
				v-if="countryCode == 'MX'"
				class="logon-logo-sm"
				:src="`${locationOrigin}/styleOverrides/encore_online.png`"
				alt="encoreonline.mx"
			/>
			<h2>{{ languageStrings.pleaseLogIn }}</h2>
			<label v-if="countryCode != 'MX'" for="phoneNumber">{{ languageStrings.playersPhoneNumber }}</label>
			<input
				type="tel"
				pattern="[0-9]+"
				id="phoneNumber"
				:name="languageStrings.playersPhoneNumber"
				@keydown.enter="login()"
				v-model="phoneNumber"
				:placeholder="languageStrings.playersPhoneNumber"
				autocomplete="off"
				:maxlength="allowPhoneExtensionDigits ? '' : phoneNumberLength"
				class="curved-border input-text-position phone-number-input-spacing login-input"
			/>
			<label v-if="countryCode != 'MX'" for="password">{{ languageStrings.password }}</label>
			<div class="password-container">
				<input
					:type="unhidePassword ? 'text' : 'password'"
					id="password"
					:name="languageStrings.password"
					@keydown.enter="login()"
					v-model="password"
					:placeholder="languageStrings.password"
					autocomplete="new-password"
					class="curved-border input-text-position login-input"
				/>
				<span
					@click="unhidePassword = unhidePassword ? false : true"
					:class="unhidePassword ? 'hidden' : ''"
					:title="unhidePassword ? languageStrings.hidePassword : languageStrings.showPassword"
				>
					<svg width="35px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
						<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
						<g id="SVGRepo_iconCarrier">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M12.0002 5C9.89024 5 7.99432 5.92747 6.49128 7.00884C4.98034 8.0959 3.78013 9.40024 3.04026 10.2985C2.21965 11.2948 2.21965 12.7052 3.04026 13.7015C3.78013 14.5998 4.98034 15.9041 6.49128 16.9912C7.99432 18.0725 9.89024 19 12.0002 19C14.1101 19 16.006 18.0725 17.5091 16.9912C19.02 15.9041 20.2202 14.5998 20.9601 13.7015C21.7807 12.7052 21.7807 11.2948 20.9601 10.2985C20.2202 9.40025 19.02 8.0959 17.5091 7.00885C16.006 5.92747 14.1101 5 12.0002 5ZM12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z"
								fill="#6305C9"
							></path>
						</g>
					</svg>
				</span>
			</div>
			<span v-if="capsLockOn" class="cap-lock">{{
				languageStrings.capLockIsOn
			}}</span>
			<div class="encore-style-button-container">
				<button
					class="btn encore-style-button curved-border"
					type="button"
					:title="languageStrings.loginButton"
					@click="login()"
				>
					{{ languageStrings.loginButton }}
				</button>
			</div>
			<router-link
				v-if="countryCode === 'MX'"
				to="/extendedRegisterView"
				:title="languageStrings.register"
				class="new-user-link"
				@click="clearFields()"
				>{{ languageStrings.register }}</router-link
			>
			<router-link
				v-if="countryCode !== 'MX'"
				to="/multiComponentRegisterView"
				:title="languageStrings.register"
				class="new-user-link"
				@click="clearFields()"
				>{{ languageStrings.register }}</router-link
			>
			<span :title="languageStrings.forgotPassword" id="lost-pw-modal" @click="lostPasswordModal()">{{
				languageStrings.forgotPassword
			}}</span>
		</form>
	</div>
	<dialog id="logout-dialog" :class="countryCode == 'MX' ? 'encore-background' : ''">
		<div>
			<p>
				{{ `${this.languageErrorStrings.logoutConfirm}, ${this.playerState.displayName}` }}
			</p>
			<div id="btn-container">
				<button id="confirmButton" class="btn new-encore-button" value="default" @click="logout()">Ok</button>
				<button id="cancelButton" class="btn new-encore-button" value="cancel" formmethod="dialog" @click="closeModal()">
					{{ languageStrings.cancel }}
				</button>
			</div>
		</div>
	</dialog>
	<transition name="fade">
		<component
			v-bind:is="currentModal"
			:capsLockOn="capsLockOn"
			:serverRules="serverRules"
			:isMobile="isMobile"
			:languageStrings="languageStrings"
			:languageErrorStrings="languageErrorStrings"
		/>
	</transition>
</template>

<script>
import router from "@/router";
import { useRoute } from "vue-router";
import { onBeforeUnmount } from "vue";
import PasswordReset from "@/components/PasswordReset";
import sharedScripts from "@/dependencies/sharedScripts";
import CurrencyTool from "@/dependencies/currencyTool";

export default {
	name: "Login",
	components: {
		PasswordReset,
	},
	props: {
		playerState: Object,
		capsLockOn: Boolean,
		serverRules: Object,
		hideLoginForm: Boolean,
		isMobile: Boolean,
		casinoList: Array,
		systemSettings: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			allowPhoneExtensionDigits: false,
			unhidePassword: false,
			currentModal: null,
			phoneNumber: "",
			password: "",
			selectedCountry: {},
			systemSettingsLoaded: false,
			serverBusy: false,
			busyText: "",
			inputDialog: null,
			locationOrigin: "",
			guestAccessToken: null,
			guestRefreshToken: null,
			guestTokenExpiration: null,
			route: useRoute(),
		};
	},
	watch: {
		playerState: {
			handler() {
				this.phoneNumber = this.playerState?.phoneNumber;
				if (Object.keys(this.playerState).length > 0 && this.playerState?.guestLogin) {

					// this.eventBus.emit("listDocumentAcknowlegement");
					let _state = this.playerState;
					_state.guestLogin = false;
					this.eventBus.emit("updatePlayerState", _state);
					this.eventBus.emit("updateUserProfile");
					router.push("/webPayCashIn");
				}
			},
			deep: true,
		},
	},
	created() {
		// if (this.guestAccessToken && !this.playerState.loggedIn) this.guestLogin();

		// this.eventBus.on("guestLogin", (payload) => {
		// 	if (this.guestAccessToken && !this.playerState.loggedIn) this.guestLogin();
		// });
		// this.eventBus.on("updateGuestState", () => {
		// 	// This is to fix a race condition
		// 	// this.eventBus.emit("updateCasinosIncludingDeleted", this.status.ok);
		// 	// if (!fetchStatus.message == 200) this.eventBus.emit("updateStatus", fetchStatus);
		// 	this.eventBus.emit("updateUserProfile");

		// 	console.log(this.playerState);

		// 	this.eventBus.emit("listDocumentAcknowlegement");
		// 	let _state = this.playerState;
		// 	_state.guestLogin = false;
		// 	this.eventBus.emit("updatePlayerState", _state);
		// 	router.push("/webPayCashIn");
		// });
		this.eventBus.on("countrySelected", (payload) => {
			this.selectedCountry = payload;
		});
		this.eventBus.on("serverSettingsLoaded", (payload) => {
			this.allowPhoneExtensionDigits = payload.allowPhoneExtensionDigits;
		});
		this.eventBus.on("returnFromResetPW", () => {
			this.resetModal();
		});
		this.eventBus.on("captchaDismissed", () => {
			this.resetModal();
		});
		this.eventBus.on("forceLogout", () => {
			this.logout(true);
		});
		this.eventBus.on("regularLogout", () => {
			this.showLogoutDialog();
		});
		onBeforeUnmount(() => {
			this.eventBus.off("updateGuestState");
			this.eventBus.off("guestLogin");
			this.eventBus.off("countrySelected");
			this.eventBus.off("serverSettingsLoaded");
			this.eventBus.off("returnFromResetPW");
			this.eventBus.off("captchaDismissed");
			this.eventBus.off("forceLogout");
			this.eventBus.off("regularLogout");
		});
	},
	mounted() {
		this.locationOrigin = window.location.origin;
		this.inputDialog = document.getElementById("logout-dialog");
		// this.resolveQueryParams(); // commented out due to guest login moved to it's own component
	},
	methods: {
		resolveQueryParams() {
			let location = window.location;
			let urlParams = null;

			if (location.hash) {
				location = new URL(location.href.replace("#/", ""));
				urlParams = new URLSearchParams(location.search);
			} else {
				urlParams = new URLSearchParams(location.search);
			}

			for (const [key, value] of urlParams) {
				if (key === "accessToken") this.guestAccessToken = value;
				if (key === "refreshToken") this.guestRefreshToken = value;
				if (key === "accessTokenExpiration") this.guestTokenExpiration = new Date(value);
			}
			if (this.guestAccessToken) this.sessionFromGuest();
		},
		guestLogout() {
			window.location.search = "?loggedin=false";
		},
		resetModal() {
			this.currentModal = null;
			this.password = "";
			this.phoneNumber = "";
			let loginElem = document.getElementById("login");
			if (loginElem && loginElem.classList.contains("hide")) loginElem.classList.remove("hide");
		},
		lostPasswordModal() {
			let loginElem = document.getElementById("login");
			loginElem.classList.add("hide");
			this.currentModal = "PasswordReset";
		},
		async getCurrentBalances(account) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${account.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/funds", this.rabbitsfootHostUrl);

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				dataJson.forEach((balance) => {
					let matchedByCasinoId = this.casinoList.filter((casino) => balance.casinoId == casino.id)[0];
					// Insert an instance of the currency tool class specific to each casino's in-game currency rules.
					balance.currencyTool = new CurrencyTool(matchedByCasinoId.currencyInfo, this.localeString);
				});

				return dataJson;
			} catch (e) {
				console.error(e);
			}
		},
		async login() {
			let hasErrors = false;
			this.serverBusy = true;
			this.busyText = this.languageStrings.checkingCradentials;

			// if (this?.phoneNumber?.match(/[^0-9]/)) {
			// 	this.status.message = this.languageErrorStrings.numbersOnly;
			// 	this.status.ok = false;
			// 	this.eventBus.emit("updateStatus", this.status);
			// 	hasErrors = true;
			// }

			if (!this.phoneNumber || !this.password) {
				this.status.message = this.languageErrorStrings.provideValidPhoneAndPassword;
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (hasErrors) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				phoneNumber: this.phoneNumber.toString(), //.replace(/\D/g, ""),
				password: this.password,
			};

			let requestUrl = new URL("/api/v1/authentication/login", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					this.password = "";
					this.phoneNumber = "";
					return;
				}

				let dataJson = await response.json();

				dataJson.loggedIn = true;
				dataJson.permissionsSuspended = dataJson.permissions.arePermissionsSuspended;
				dataJson.guestLogin = false;
				this.password = "";
				this.unhidePassword = false;

				dataJson.casinoBalances = (await this.getCurrentBalances(dataJson)) || {};

				this.serverBusy = false;
				this.busyText = "";

				// this.eventBus.emit("updateCasinosIncludingDeleted", this.status.ok);
				if (!fetchStatus.message == 200) this.eventBus.emit("updateStatus", fetchStatus);
				this.eventBus.emit("updatePlayerState", dataJson);
				this.eventBus.emit("updateUserProfile");
				this.eventBus.emit("listDocumentAcknowlegement");
			} catch (e) {
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.password = "";
				this.phoneNumber = "";
			}
		},
		async sessionFromGuest() {
			let hasErrors = false;
			this.serverBusy = true;
			this.busyText = this.languageStrings.checkingCradentials;

			let body = {
				oldAccessToken: this.guestAccessToken,
				refreshToken: this.guestRefreshToken,
			};

			let requestUrl = new URL("/api/v1/authentication/refresh", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverBusy = false;
					this.busyText = "";
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				dataJson.loggedIn = true;
				dataJson.permissionsSuspended = dataJson?.permissions?.arePermissionsSuspended;
				dataJson.casinoBalances = (await this.getCurrentBalances(dataJson)) || {};
				dataJson.guestLogin = true;

				this.serverBusy = false;
				this.busyText = "";
				if (!fetchStatus.message == 200) this.eventBus.emit("updateStatus", fetchStatus);
				this.eventBus.emit("updatePlayerState", dataJson);
			} catch (e) {
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
			}
		},
		showLogoutDialog() {
			this.inputDialog.showModal();
		},
		closeModal() {
			this.inputDialog.close();
		},
		logout(forced = false) {
			let playerState = {};
			this.password = "";
			this.unhidePassword = false;
			this.phoneNumber = "";
			this.eventBus.emit("updatePlayerState", playerState);
			this.eventBus.emit("loggedOut");
			this.eventBus.emit("toggleNavBar", true);
			this.eventBus.emit("closeChangeDisplayNameModal");
			this.eventBus.emit("closeDocumentsDisplay");
			this.closeModal();
			router.push("/");
		},

		clearFields() {
			this.password = "";
			this.phoneNumber = "";
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#loading {
	display: grid;
	align-content: center;
	position: fixed;
	width: 100%;
	height: 100%;
	text-align: center;
	font-weight: bold;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
	z-index: 100;
}

.loading-message {
	text-align: center;
	display: block;
	margin: 15px;
}

h3 {
	text-align: center;
}

#login {
	display: grid;
	height: 100%;
	align-content: center;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background-color: rgb(0 0 0 / 50%);
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
}

#login.loggedin {
	height: auto;
	z-index: 150;
}

#logout-dialog {
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}

::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
}

.hide {
	display: none;
}

.input-section {
	display: flex;
	flex-direction: column;
	position: relative;
	top: 0;
	width: 60%;
	max-width: 30em;
	padding: 0 30px 30px;
	margin: auto;
	background: #121832;
	border-radius: 12px;
	box-shadow: inset -1px -1px 15px 1px rgb(13 28 37 / 50%);
}

input {
	height: 30px;
	color: #6305c9;
}

.login-status {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: fixed;
	top: 33px;
	right: 0;
	padding: 5px 10px;
	font-size: 0.625em;
	background-color: #32373f;
	border-radius: 0 0 0 0.4em;
	border: 1px #5b88c0 solid;
}

.input-heading form {
	display: flex;
	flex-direction: column;
}

.login-status button {
	user-select: none;
	margin: 8px auto;
	padding: 10px;
}

label[for="casinoId"] {
	text-align: center;
	text-transform: uppercase;
	text-shadow: -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000, 1px 1px 0px #000;
}

/* .submit-login {
	padding: 5px 10px;
	margin: 10px auto;
} */

.new-user-link,
#lost-pw-modal {
	text-align: center;
	color: white;
	padding: 15px 15px 0;
	text-decoration: white underline;
	cursor: pointer;
}
.new-user-link:hover,
#lost-pw-modal:hover {
	text-decoration: #fff underline;
}

.password-container span {
	color: #6305c9;
	top: 8px;
}

.encore-style-button-container {
	margin-top: 2vh;
}

/* .encore-style-button-container:hover {
	transform: scale(1.05);
	width: auto;
}

.encore-style-button:hover {
	transform: none;
} */

.encore-style-button {
	font-size: 15px;
}

.input-text-position {
	padding-left: 15px;
}

.password-container span::after {
	/* top: -.3vh;
	right: 5vw;
	margin-left: 4vw;
	margin-right: -3.5vw; */
	top: -5px;
	right: 12.9px;
}

.nav-button-container {
	height: 2vh;
	width: 4vh;
}

/* #eyeIcon {
	margin-right: 3.5vw;
} */

.cap-lock {
	color: #ff0;
	text-align: center;
 	font-weight: 700
}

.display-name {
	margin-top: 19px;
	margin-right: 10px;
}

@media (min-width: 768px) {
	#login {
		left: unset;
		z-index: 100;
	}

	#logout-dialog {
		margin: auto;
	}
}

@media (orientation: landscape) and (max-height: 500px) {
	div#login {
		height: calc(100vh - 140px);
		overflow: hidden auto;
		top: 80px;
	}

	.input-section {
		margin: 80px auto 0;
		padding: 30px;
	}
}

@media (max-width: 767px) {
	input {
		max-width: 52vw;
	}

	.encore-style-button {
		/* height: 5.5vh !important; */
	}

	.password-container span {
		right: 35px;
	}
}

@media (max-width:532px) {
	.password-container span {
		right: 25px;
	}
}
</style>
